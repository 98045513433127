
export default {
  name: 'HomeViewBannerCenterBannerDynamicItemBottom',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  head() {
    return {
      style: [
        {
          cssText: this.item?.content?.style,
          type: 'text/css',
        },
      ],
    };
  },
};
