// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".center_banner_slider_lBO80{height:auto!important;max-width:100%}@media only screen and (min-width:1264px){.center_banner_slider_lBO80{background:var(--cp-gray-9);border-radius:.8rem;box-shadow:2px 2px 8px var(--cp-color-101);overflow:hidden}}.center_banner_slider_lBO80 a:not(.accessibility-disabled_EQ6R8):focus-visible{outline:none}.center_banner_slider_lBO80 a:not(.accessibility-disabled_EQ6R8):focus-visible:before{border:2px solid var(--cp-accessibility-color);border-radius:.8rem;content:\"\";height:100%;left:0;position:absolute;top:0;width:100%}.center_banner_slider_lBO80 div{display:block!important}.center_banner_slider_lBO80 div img{vertical-align:middle;width:100%}.center_banner_slider_slider__pointer_P1zdT{display:block;height:100%;vertical-align:middle}.center_banner_slider__banner_video_mu-sV{height:100%;vertical-align:middle}.center_banner_slider__banner_video_mu-sV video{vertical-align:middle}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center_banner_slider": "center_banner_slider_lBO80",
	"accessibility-disabled": "accessibility-disabled_EQ6R8",
	"center_banner_slider_slider__pointer": "center_banner_slider_slider__pointer_P1zdT",
	"center_banner_slider__banner_video": "center_banner_slider__banner_video_mu-sV"
};
module.exports = ___CSS_LOADER_EXPORT___;
