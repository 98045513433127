import { render, staticRenderFns } from "./CenterBannerDynamic.vue?vue&type=template&id=21b2b507&scoped=true"
import script from "./CenterBannerDynamic.vue?vue&type=script&lang=js"
export * from "./CenterBannerDynamic.vue?vue&type=script&lang=js"
import style0 from "./CenterBannerDynamic.vue?vue&type=style&index=0&id=21b2b507&prod&module=true&lang=scss"
import style1 from "./CenterBannerDynamic.vue?vue&type=style&index=1&id=21b2b507&prod&lang=scss&scoped=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "21b2b507",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeViewBannerCenterBannerDynamicItemMain: require('/var/www/html/js/productlisting/components/templates/home/view/banner/CenterBannerDynamic/ItemMain.vue').default,HomeViewBannerCenterBannerDynamicItemBottom: require('/var/www/html/js/productlisting/components/templates/home/view/banner/CenterBannerDynamic/ItemBottom.vue').default})
