
export default {
  name: 'HomeViewBannerCenterBannerDynamicItemMain',
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  head() {
    return {
      style: [
        {
          cssText: this.item?.content?.style,
          type: 'text/css',
        },
      ],
      link: [
        ...(
          this.needToPreloadImage ? [{
            // hid: this.item?.background?.banner,
            rel: 'preload',
            as: 'image',
            href: this.item?.background?.banner,
          }] : []
        ),
      ],
    };
  },
  computed: {
    showPoster() {
      return this.$isSpider
        && this.item?.background?.video
        && this.item?.background?.banner;
    },
    needToPreloadImage() {
      return this.item?.background?.video && this.item?.background?.banner;
    },
  },
};
