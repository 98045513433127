
import KeenSlider from 'keen-slider';
import respUtility from '@/mixins/respUtility';
import 'keen-slider/keen-slider.min.css';

export default {
  name: 'HomeViewBannerCenterBannerDynamic',
  mixins: [respUtility],
  props: {
    bannerItems: {
      type: Array,
      default() {
        return [];
      },
    },
    device: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      slider: null,
      current: 0,
      opacities: [],
    };
  },
  computed: {
    dotHelper() {
      return this.slider ? [...Array(this.slider?.track?.details?.slides?.length).keys()] : [];
    },
    centerUpperBanner() {
      return this.deviceSpecificBanner?.filter((banner) => banner.useInSlider);
    },
    centerDownBanner() {
      return this.deviceSpecificBanner?.filter((banner) => !banner.useInSlider);
    },
    deviceSpecificBanner() {
      let banners = [];
      if (this?.device?.isDesktop) {
        banners = this.bannerItems.filter((banner) => banner?.isDesktop);
      } else if (this?.device?.isTablet) {
        banners = this.bannerItems.filter((banner) => banner?.isTablet);
      } else if (this?.device?.isMobile) {
        banners = this.bannerItems.filter((banner) => banner?.isMobile);
      }
      return banners;
    },
  },
  methods: {
    getCurrentSlideSpeed() {
      const currentSlideIndex = this?.slider?.track?.details?.rel;
      const currentSliderEle = document.getElementById(`center_banner_slider__link_${currentSlideIndex}`);
      const video = currentSliderEle ? currentSliderEle.querySelector('video') : null;
      if (video) {
        const source = video.querySelector('source');
        if (source && source.src && source.src.includes('.mp4')) {
          return video.duration * 1000 || 7000;
        }
      }
      return this.centerUpperBanner[currentSlideIndex]?.speed || 7000;
    },
    playSliderMobile() {
      if (this.slider) {
        this.slider.destroy();
        this.slider = null;
      }
      const sliderEle = this.$refs.bannerSlider;
      if (!sliderEle || this.centerUpperBanner?.length <= 1) {
        return;
      }
      const slidesPerView = 1;
      this.slider = new KeenSlider(
        sliderEle,
        {
          slides: this.centerUpperBanner.length,
          initial: 0,
          disabled: false,
          drag: true,
          loop: true,
          slidesPerView,
          slideChanged: (s) => {
            this.current = s?.track?.details?.rel;
            this.playCurrentVideo();
          },
        },
        [
          this.sliderPlugin,
        ],
      );
      this.setInitialTimeout();
    },
    playSlider() {
      if (this.slider) {
        this.slider.destroy();
        this.slider = null;
      }
      const sliderEle = this.$refs.bannerSlider;
      if (!sliderEle || this.centerUpperBanner?.length <= 1) {
        return;
      }
      this.slider = new KeenSlider(
        sliderEle,
        {
          slides: this.centerUpperBanner.length,
          drag: true,
          loop: true,
          slidesPerView: 1,
          detailsChanged: (s) => {
            this.opacities = s.track.details.slides.map((slide) => slide.portion);
            // this.current = s.track.details.rel;
          },
          slideChanged: (s) => {
            this.current = s.track.details.rel;
            this.playCurrentVideo();
          },
        },
        [
          this.sliderPlugin,
        ],
      );
      this.setInitialTimeout();
    },
    setInitialTimeout() {
      const time = this.getCurrentSlideSpeed();
      this.playCurrentVideo();
      setTimeout(() => {
        this?.slider?.next();
      }, time);
    },
    sliderPlugin(slider) {
      let timeout;
      function clearNextTimeout() {
        clearTimeout(timeout);
      }
      const nextTimeout = () => {
        clearTimeout(timeout);
        const time = this.getCurrentSlideSpeed();
        timeout = setTimeout(() => {
          slider?.next();
        }, time);
      };
      slider.on('dragStarted', clearNextTimeout);
      slider.on('animationEnded', nextTimeout);
      slider.on('updated', nextTimeout);
    },
    playCurrentVideo(currentSlideIndex = null) {
      const currentIndex = currentSlideIndex ?? this.current;
      const currentSliderEle = document.getElementById(`center_banner_slider__link_${currentIndex}`);
      const video = currentSliderEle ? currentSliderEle.querySelector('video') : null;
      if (video) {
        const source = video.querySelector('source');
        if (source && source.src && source.src.includes('.mp4')) {
          video.currentTime = 0;
          if (this.$device.isDesktop) {
            setTimeout(() => {
              video.play();
            }, 1000);
          } else {
            video.play();
          }
        }
      }
    },
  },
  beforeDestroy() {
    this.slider?.destroy();
  },
  watch: {
    deviceSpecificBanner: {
      handler() {
        setTimeout(() => {
          if (this.$device.isDesktop) {
            this.playSlider();
          } else {
            this.playSliderMobile();
          }
        }, 0);
      },
      immediate: true,
    },
  },
  head() {
    return {
      style: [
        {
          cssText: this.item?.style,
          type: 'text/css',
        },
      ],
    };
  },
};
